<template>
  <div class="home" :style="isOverflow ? 'overflow: hidden;' : ''">
    <div class="headerContainer">
      <div class="header">
        <div class="conter">
          <div class="logo">
            <img
              src="../../assets/images/web/knotlogo.png"
              alt=""
            />成都卡恩特医疗科技有限公司
          </div>
          <div class="nav">
            <el-menu
              :default-active="menuActive"
              class="el-menu-demo"
              mode="horizontal"
              active-text-color="#20ADFF"
              @select="handleSelect"
              text-color="#fff"
              background-color="#0b1a35"
              :router="true"
            >
              <el-menu-item index="/web/home">首页</el-menu-item>
              <el-menu-item index="/web/product">项目与产品</el-menu-item>
              <el-menu-item index="/web/journalism">新闻中心</el-menu-item>
              <el-menu-item index="/web/recruit">人才招聘</el-menu-item>
              <el-menu-item index="/web/about">关于我们</el-menu-item>
              <el-menu-item class="hoverAccount"
                >公众号
                <!-- 公众号得图片 -->
                <div style="background: #fff" class="officialAccount">
                  <img
                    src="../../assets/images/web/officialAccount.png"
                    alt=""
                  />
                  <p>扫码关注指云端医疗</p>
                </div>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="mian">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuActive: '',
      isOverflow: false
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath)
    }
  },
  activated() {},
  watch: {
    $route: {
      handler(data) {
        const { path } = this.$route || {}
        if (path === '/web/home') {
          this.isOverflow = true
        } else {
          this.isOverflow = false
        }
        if (path.includes('journalism')) {
          this.menuActive = '/web/journalism'
          return
        }
        if (path.includes('/product/')) {
          this.menuActive = '/web/product'
          return
        }
        this.menuActive = path
      },
      deep: true
    }
  },
  created() {
    const { path } = this.$route || {}
    if (path.includes('/product/')) {
      this.menuActive = '/web/product'
      return
    }
    if (path.includes('journalism')) {
      this.menuActive = '/web/journalism'
      return
    }
    this.menuActive = path
  },
  mounted() {
    const { path } = this.$route || {}
    if (path === '/web/home') {
      this.isOverflow = true
    } else {
      this.isOverflow = false
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  .headerContainer {
    height: 67px;
  }
  .header {
    background: #0b1a35;
    position: fixed;
    width: 100%;
    height: 68px;
    z-index: 111;
    .conter {
      color: #fff;
      width: 1200px;
      line-height: 68px;
      display: flex;
      justify-content: space-between;
      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 29px;
          height: 38px;
          margin-right: 10px;
        }
      }

      .nav {
        /deep/.el-menu--horizontal > .el-menu-item {
          height: 68px !important;
          border-bottom: 3px solid #20adff;
          font-size: 17px;
        }
        .el-menu.el-menu--horizontal {
          border-bottom: none;
        }
      }
    }
  }
  .mian {
    height: 100%;
  }
  .hoverAccount {
    position: relative;
  }

  .officialAccount {
    width: 244px;
    height: 292px;
    background-color: #fff;
    position: absolute;
    right: 0px;
    font-size: 20px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #232424;
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: hidden;
    margin: auto;
    top: 80px;
    img {
      margin-top: 10px;
      width: 222px;
      height: 220px;
    }
    p {
      margin: 0;
    }
  }
  .hoverAccount:hover {
    .officialAccount {
      visibility: visible;
    }
  }
}
</style>
