<template>
  <div class="newsdetails">
    <div class="conter">
      <div class="news-header">
        <p style="margin: 0">新闻详情</p>
        <p style="margin: 0">
          <span @click="returnPage('返回首页')">首页</span> /
          <span @click="returnPage('新闻')">新闻</span> / 新闻详情
        </p>
      </div>
      <h1 style="margin: 0" class="title">{{ newsDetailMsg.newsTitle }}</h1>
      <p class="time">
        {{ newsDetailMsg.createTime }}
      </p>
      <div class="ql-editor" v-html="newsDetailMsg.newsDetail"></div>
      <div class="btn" @click="returnPage('新闻')">
        <i class="el-icon-arrow-left"></i>
        返回首页
      </div>
    </div>
    <Footer style="margin-top: 112px" />
  </div>
</template>

<script>
import { newsDetail } from '@/api/journalism'
import Footer from '@/components/webFooter/footerBottom.vue'
import moment from 'moment'
export default {
  components: {
    Footer
  },
  data() {
    return { newsDetailMsg: {} }
  },
  methods: {
    //   获取新闻详细
    async getNewsDatails(newsId) {
      const { code, data } = await newsDetail(newsId)
      if (code === '0000') {
        console.log(data)
        data.newsDetail = data.newsDetail.replaceAll(
          '<img',
          "<img style='width:100%;margin-top:10px;'"
        )
        data.newsDetail = data.newsDetail.replaceAll('<strong', '<b')
        data.newsDetail = data.newsDetail.replaceAll('</strong', '</b')
        data.createTime = moment(data.createTime).format('YYYY-MM-DD')
        this.newsDetailMsg = data
      }
    },
    returnPage(e) {
      if (e === '返回首页') {
        this.$router.push('/homepage')
        return
      }
      if (e === '新闻') {
        this.$router.push('/web/journalism')
      }
    },
    formatDate(time) {
      const date = new Date(time)
      const year = date.getFullYear()
      const month = date.getMonth()
      const day = date.getDate()
      // const hour = date.getHours()
      // const min = date.getMinutes()
      // const second = date.getSeconds()
      return (
        year + '-' + month + '-' + day
        // + ' ' + hour + ':' + min + ':' + second
      )
    }
  },
  created() {
    const newsId = this.$route.query.newsId || {}
    this.getNewsDatails(newsId)
  }
}
</script>

<style lang="less" scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px;
}
.MsoNormal {
  line-height: 60px !important;
  letter-spacing: 30px !important;
}
.newsdetails {
  .news-header {
    display: flex;
    justify-content: space-between;
    padding: 100px 0 100px 0;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: bold;
    align-items: flex-end;
    color: #29262a;
    p:nth-child(2) {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #29262a;
      span {
        cursor: pointer;
      }
      span:hover {
        color: #20adff;
      }
    }
  }
  .title {
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #3c3c3c;
    line-height: 50px;
    text-align: center;
    margin-bottom: 25px;
  }
  .time {
    text-align: center;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #afadb0;
    // line-height: 50px;
    padding-bottom: 50px;
  }
  .btn {
    width: 186px;
    height: 50px;
    background: #0c2948;
    border-radius: 25px;
    color: #fff;
    line-height: 50px;
    text-align: center;
    margin: auto;
    margin-top: 93px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      width: 30px;
      height: 30px;
      background: #ffffff;
      line-height: 30px;
      border-radius: 50%;
      margin-right: 10px;
      color: #0c2948;
    }
  }
}
/deep/p {
  line-height: 30px;
  letter-spacing: 2px !important;
}
</style>
