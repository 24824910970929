<template>
  <div ref="home" class="phone-home">
    <div class="div">
      <div ref="header" class="header">
        <header>
          <div>
            <img src="../../assets/images/web/knotlogo.png" alt="" />
            成都卡恩特医疗科技有限公司
          </div>
          <i @click="drawer = true" class="el-icon-s-unfold"></i>
        </header>
      </div>
    </div>
    <!-- 导航 -->
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      size="50%"
      :modal="false"
    >
      <div class="nav">
        <div class="title">
          <i class="el-icon-close" @click="drawer = false"></i>
        </div>
        <div class="nav-item">
          <el-menu
            :default-active="menuActive"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            background-color="#0B1A35"
            text-color="#FFFEFF"
            active-text-color="#36DAF2"
            :router="true"
          >
            <el-menu-item index="/phone/home">
              <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item index="/phone/product">
              <span slot="title">项目与产品</span>
            </el-menu-item>
            <el-menu-item index="/phone/journalism">
              <span slot="title">新闻中心</span>
            </el-menu-item>
            <el-menu-item index="/phone/recruit">
              <span slot="title">人才招聘</span>
            </el-menu-item>
            <el-menu-item index="/phone/about">
              <span slot="title">关于我们</span>
            </el-menu-item>
            <el-menu-item index="/phone/account">
              <span slot="title">公众号</span>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </el-drawer>
    <div class="mian">
      <router-view class="router" :minHeight="minHeight"></router-view>
    </div>
  </div>
</template>

<script>
// import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      drawer: false,
      minHeight: 0,
      homeHeight: 0,
      clientHeight: 0,
      menuActive: '/phone'
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath)
    },
    // 计算高度
    getHeight() {
      console.log('高度变化')
      const headerHeight = this.$refs.header.offsetHeight
      this.homeHeight = this.$refs.home.offsetHeight
      this.minHeight = this.homeHeight - headerHeight
      this.$store.commit('getMinHeightFn', this.minHeight)
    },
    getHeight1() {
      // 页面高度
      this.clientHeight = document.body.clientHeight
    }
  },
  created() {
    this.getHeight1()
    const { path } = this.$route || {}
    console.log(path, 'path')
    if (path.includes('/product/')) {
      this.menuActive = '/phone/product'
      return
    }
    if (path.includes('journalism')) {
      this.menuActive = '/phone/journalism'
      return
    }
    this.menuActive = path
  },
  watch: {
    $route: {
      handler(data) {
        this.drawer = false
        const { path } = this.$route || {}
        console.log(path, 'path')
        if (path.includes('/product/')) {
          this.menuActive = '/phone/product'
          return
        }
        if (path.includes('journalism')) {
          this.menuActive = '/phone/journalism'
          return
        }
        this.menuActive = path
      },
      deep: true
    }
  },
  mounted() {
    this.getHeight()
    window.onresize = () => {
      this.getHeight()
    }

    addEventListener("load", function() { setTimeout(hideURLbar, 0); }, false); 
      function hideURLbar(){ 
                window.scrollTo(0,1); 
      }
  }
}
</script>

<style lang="less" scoped>
.phone-home {
  width: 100%;
  height: 100%;
  .div {
    height: 91px;
  }
  .header {
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
    height: 91px;
    padding: 0 40px;
    background: #0b1a35;
    box-sizing: border-box;
    header {
      display: flex;
      height: 91px;
      background: #0b1a35;
      font-weight: 400;
      color: #ffffff;
      width: 100%;
      font-size: 24px;
      font-family: FZLanTingHei-R-GBK;
      justify-content: space-between;
      align-items: center;

      i {
        font-size: 36px;
        color: #ffffff;
      }
      div {
        display: flex;
        align-items: center;
        img {
          width: 29px;
          height: 38px;
          margin-right: 20px;
        }
      }
    }
  }
  .nav {
    background-color: #0b1a35;
    height: 100%;
    // height: 400px;
    color: #fff;
    font-size: 18px;
    .title {
      height: 91px;
      background-color: #0b1a35;
      i {
        font-size: 50px;
        padding: 18px;
        float: right;
      }
    }
    /deep/.el-menu-item.is-active {
      background-color: #2e3b52 !important;
      // border: none;
    }
    .el-menu-item {
      border-bottom: 1px solid #26334a;
      // opacity: 0.3;
      margin: 0 30px;
    }
  }
}
</style>
